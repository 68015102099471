<template>
    <div :key="itemListKey">
        <v-layout row pt-2>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.volume') }}</v-flex>
            <v-flex xs6 lg2 md3 py-1 class="d-flex flex-row">
                <v-text-field
                        :counter="false"
                        :value="Wpr__containercount"
                        :rules="[4,7].indexOf(Wpr__containersize_id) == -1 ? [...validationRules.required, ...validationRules.number, ...validationRules.minimumOne] : [...validationRules.number, ...validationRules.minimumOne]"
                        class="custom bg-lightgrey"
                        dense
                        hide-details="auto"
                        @change="Wpr__containercount = $event"
                        v-if="[4,7].indexOf(Wpr__containersize_id) == -1"
                />
                <v-icon small class="mx-1 mt-0" v-if="[4,7].indexOf(Wpr__containersize_id) == -1" color="black darken-1">mdi-close</v-icon>
                <v-select
                        :items="containerSizes"
                        class="custom bg-lightgrey"
                        dense
                        hide-details="auto"
                        item-text="Containersize.title"
                        item-value="Containersize.id"
                        reverse
                        v-model="Wpr__containersize_id"
                />
            </v-flex>
        </v-layout>
        <v-form lazy-validation ref="wprItemsForm" v-model="validForm">
            <v-data-table
                    :headers="headers"
                    :items="Wpritems"
                    calculate-widths
                    class="mt-3 appic-table-light specification-table ml-2"
                    dense
                    disable-pagination
                    disable-sort
                    hide-default-header
                    hide-default-footer
                    item-key="index"
                    single-select
            >
                <template v-slot:header="{ props: { headers } }">
                    <tr>
                        <th class="grey lighten-3 pa-1 text-left width-45-pct">
                            <v-row no-gutters>
                                <v-col cols="5">{{ $t('message.product') }}</v-col>
                                <v-col cols="7" class="pl-1">{{ $t('message.spec') }}</v-col>
                            </v-row>
                        </th>
                        <th class="grey lighten-3 pa-1 text-left">{{ headers[1].text }}</th>
                        <th class="grey lighten-3 pa-1 text-left width-25-pct">{{ headers[2].text }}</th>
                        <th class="grey lighten-3 pa-1 text-center width-1-pct">{{ headers[3].text }}</th>
                    </tr>
                </template>
                <template v-slot:item.Wpritem.productgroup_id="{item}">
                    <v-row no-gutters>
                        <v-col cols="5">
                            <div class="d-flex flex-row align-center">
                                <div style="min-width: 100px" class="font-16px">{{ $t('message.product') + ":" }}</div>
                                <v-autocomplete
                                        :id="'product_' + item.index"
                                        :items=allProductGroups
                                        :placeholder="item.Wpritem.productgroup_id ? '' : $t('message.required')"
                                        :rules="[...validationRules.required]"
                                        :value="item.Wpritem.productgroup_id"
                                        class="custom pa-1 bg-lightgrey"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        item-text="ProductGroup.title"
                                        item-value="ProductGroup.id"
                                        @change="updateWprItemRowHandle( item.index, 'Wpritem.productgroup_id',$event)"
                                />
                            </div>
                            <div class="d-flex flex-row align-center">
                                <div style="min-width: 100px" class="font-16px">{{ $t('message.species') + ":" }}</div>
                                <v-autocomplete
                                        :id="'species_' + item.index"
                                        :items="item.Options.species"
                                        :placeholder="item.Options.species.length > 0 ? $t('message.select') : $t('message.selectProductFirst')"
                                        :rules="[...validationRules.required]"
                                        :value="item.Wpritem.species_id"
                                        clearable
                                        class="custom pa-1 bg-lightgrey"
                                        dense
                                        hide-details="auto"
                                        item-value="Species.id"
                                        item-text="Species.title"
                                        @input="updateWprItemRowHandle( item.index, 'Wpritem.species_id',$event)"
                                />
                            </div>
                            <div class="d-flex flex-row align-center">
                                <div style="min-width: 100px" class="font-16px">{{ $t('message.certification') + ":" }}</div>
                                <v-select
                                        :id="'certification_' + item.index"
                                        :items="allCertifications"
                                        :value="item.Wpritem.certification_id"
                                        class="text-right custom pa-1 bg-lightgrey"
                                        dense
                                        hide-details="auto"
                                        item-value="Certification.id"
                                        item-text="Certification.title"
                                        @change="updateWprItemRowHandle( item.index, 'Wpritem.certification_id',$event)"
                                />
                            </div>
                        </v-col>
                        <v-col cols="7" class="pl-1">
                            <div class="d-flex flex-row align-center">
                                <div style="width: 50px" class="font-16px">{{ $t('message.grade') + ":" }}</div>
                                <v-autocomplete
                                        :id="'grade_' + item.index"
                                        :items="item.Options.grades"
                                        :placeholder="item.Options.grades.length > 0 ? $t('message.select') : $t('message.selectProductFirst')"
                                        :value="item.Wpritem.grade_id"
                                        class="custom pa-1 bg-lightgrey"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        item-text="Grade.title"
                                        item-value="Grade.id"
                                        return-object
                                        @focus="currentRowIndex = item.index"
                                        @change="updateWprItemRowHandle( item.index, 'Wpritem.grade_id',$event)"
                                >
                                    <template v-slot:selection="{ item }">
                                        <div class="text-wrap" style="width: 100%">{{ item.Grade.title }}</div>
                                    </template>
                                </v-autocomplete>
                            </div>
                            <div class="d-flex flex-row align-center">
                                <div style="width: 50px" class="font-16px">{{ $t('message.spec') + ":" }}</div>
                                <v-combobox
                                        :id="'spec_' + item.index"
                                        :items="item.Options.specs"
                                        :placeholder="item.Options.specs.length > 0 ? $t('message.select') : $t('message.selectProductFirst')"
                                        :rules="[...validationRules.plainTextWithNumberAndSymbols]"
                                        :value="item.Spec.title"
                                        class="custom pa-1 bg-lightgrey"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        item-value="Spec.id"
                                        item-text="Spec.title"
                                        return-object
                                        @focus="currentRowIndex = item.index"
                                        @change="updateWprItemRowHandle( item.index, 'Wpritem.spec_id',$event)"
                                />
                            </div>
                            <div class="d-flex flex-row align-center" v-if="item.Wpritem.productgroup_id != 15">
                                <div style="width: 50px" class="font-16px">{{ $t('message.mc') + ":" }}</div>
                                <v-select
                                        :id="'mc_' + item.index"
                                        :items="allMoistureContent"
                                        :value="item.Wpritem.mc_id"
                                        class="custom pa-1 bg-lightgrey"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        item-text="Mc.title"
                                        item-value="Mc.id"
                                        @focus="currentRowIndex = item.index"
                                        @change="updateWprItemRowHandle( item.index, 'Wpritem.mc_id',$event)"
                                />
                            </div>
                        </v-col>
                    </v-row>
                    <div class="d-flex flex-row align-center" style="height: 35px">
                        <div class="font-16px">{{ $t('message.specificationAsShownContract') + ": " + getUiDescription(item) }}</div>
                    </div>
                    <v-row no-gutters>
                        <v-col cols="12" class="pb-2">
                            <div class="d-flex flex-row align-center">
                                <div style="width: 100px" class="font-16px">{{ $t('message.addtlDescr') + ":" }}</div>
                                <v-text-field
                                        :id="'description_' + item.index"
                                        :rules="[...validationRules.plainTextWithNumberAndSymbols]"
                                        :value="item.Wpritem.add_desc"
                                        class="custom pa-1 bg-lightgrey force-text-left"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        @change="updateWprItemRowHandle( item.index, 'Wpritem.add_desc',$event)"
                                />
                            </div>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:item.Wpritem.size_id="{ item }">
                    <div class="d-flex flex-row align-center">
                        <div style="width: 75px" class="font-16px">{{ $t('message.thickness') + ":" }}</div>
                        <v-autocomplete
                                :id="'thickness_' + item.index"
                                :value="item.Wpritem.thickness_id"
                                :items="filteredThickness"
                                class="custom pa-1 bg-lightgrey"
                                clearable
                                dense
                                hide-details="auto"
                                item-text="Thickness.title"
                                item-value="Thickness.id"
                                @focus="currentRowIndex = item.index"
                                @change="updateWprItemRowHandle( item.index, 'Wpritem.thickness_id',$event)"
                        />
                    </div>
                    <div class="d-flex flex-row align-center">
                        <div style="width: 75px"class="font-16px">{{ $t('message.width') + ":" }}</div>
                        <v-text-field
                                :id="'width_' + item.index"
                                :rules="[...validationRules.plainTextWithNumberAndSymbols]"
                                :value="item.Wpritem.width"
                                class="custom pa-1 bg-lightgrey force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                @focus="currentRowIndex = item.index"
                                @change="updateWprItemRowHandle( item.index, 'Wpritem.width',$event)"
                        />
                    </div>
                    <div class="d-flex flex-row align-center">
                        <div style="width: 75px"class="font-16px">{{ $t('message.length') + ":" }}</div>
                        <v-text-field
                                :id="'length_' + item.index"
                                :rules="[...validationRules.plainTextWithNumberAndSymbols]"
                                :value="item.Wpritem.length"
                                class="custom pa-1 bg-lightgrey force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                @focus="currentRowIndex = item.index"
                                @change="updateWprItemRowHandle( item.index, 'Wpritem.length',$event)"
                        />
                    </div>
                    <div class="d-flex flex-row align-center" style="height: 35px">
                        <div class="font-16px">{{ $t('message.sizeAsShownContract') + ": " + (item.Wpritem.size_text ? item.Wpritem.size_text : '') }}</div>
                    </div>
                </template>
                <template v-slot:item.Wpritem.volume="{ item }">
                    <div class="d-flex flex-row align-center">
                        <div style="width: 100px" class="font-16px">{{ $t('message.quantity') + ":" }}</div>
                        <v-text-field
                                :id="'volume_' + item.index"
                                :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                :value="item.Wpritem.volume"
                                class="custom pa-1 bg-lightgrey"
                                dense
                                hide-details="auto"
                                @focus="currentRowIndex = item.index"
                                @change="updateWprItemRowHandle( item.index, 'Wpritem.volume',$event)"
                        />
                        <div style="width: 100px;" class="ml-2">
                            <v-select
                                    :id="'uofm_' + item.index"
                                    :items="filteredUnits"
                                    :value="item.Wpritem.measurement_id"
                                    class="text-right custom pa-1 bg-lightgrey"
                                    dense
                                    hide-details="auto"
                                    item-value="Measurement.id"
                                    item-text="Measurement.title"
                                    reverse
                                    @focus="currentRowIndex = item.index"
                                    @change="updateWprItemRowHandle( item.index, 'Wpritem.measurement_id',$event)"
                            />
                        </div>
                    </div>
                    <div class="d-flex flex-row align-center">
                        <div style="width: 100px" class="font-16px">{{ $t('message.costPrice') + ":" }}</div>
                        <v-text-field
                                :id="'unitprice_' + item.index"
                                :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                :value="item.Wpritem.unitprice"
                                class="custom pa-1 bg-lightgrey"
                                dense
                                hide-details="auto"
                                @change="updateWprItemRowHandle( item.index, 'Wpritem.unitprice',$event)"
                        />
                        <div class="ml-2 font-16px d-flex flex-row align-center" style="width: 100px" v-if="item.index == 0">
                            <v-select
                                    :items="allCurrencies"
                                    class="custom pa-1 bg-lightgrey"
                                    dense
                                    hide-details="auto"
                                    item-text="Currency.code"
                                    item-value="Currency.id"
                                    reverse
                                    v-model="Wpr__currency_id"
                            />
                            <div class>{{ '/' + getUnit(item.Wpritem.measurement_id) }}</div>
                        </div>
                        <div class="ml-2 font-16px text-right" style="width: 100px" v-if="item.index > 0">{{ getCurrencyUnit(Wpr__currency_id, item.Wpritem.measurement_id) }}</div>
                    </div>
                </template>
                <template v-slot:item.index="{ item }">
                    <div class="ml-2" v-if="Wpr__status_id == 1">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon @click="deleteItem(item.index, item.Wpritem.id)" v-on="on">
                                    <v-icon color="grey">delete</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('message.deleteThisItem') }}</span>
                        </v-tooltip>
                    </div>
                    <div v-else class="mt-2 text-center font-weight-bold">{{ $t('message.na') }}</div>
                </template>
                <template v-slot:footer>
                    <div class="pt-0 d-flex justify-space-between">
                        <div class="col-4 col-lg-4 col-md-3 pt-5 px-0 d-flex justify-start align-end">
                            <v-btn
                                    @click="addWprItemRow(someWprData)"
                                    color="success"
                                    small
                            >{{ Wpritems.length == 0 ? $t('message.addItem') : $t('message.addMore') }}
                            </v-btn>
                            <v-btn
                                    :loading="resettingWprItems"
                                    class="ml-2"
                                    color="default"
                                    small
                                    v-if="updateMode == false"
                                    @click="resetItems(statePrefix)"
                            ><v-icon>mdi-repeat</v-icon> {{ $t('message.reset') }}
                            </v-btn>
                        </div>
                        <div class="dense-inputs col-8 col-lg-8 col-md-9 pa-0">
                            <v-row justify="end">
                                <v-col cols="1" lg="2" md="2" xs="3" class="pl-3 pb-0">
                                    <div class="font-weight-black">{{ $t('message.totalVol') }}</div>
                                    <div class="mt-1 font-weight-black">{{ getVolume() }}</div>
                                </v-col>
                                <v-col cols="2" lg="2" md="2" xs="3" class="pl-3 pb-0">
                                    <div class="font-weight-black">{{ $t('message.totalQty') }}</div>
                                    <div class="font-weight-black d-flex flex-column mt-1">
                                        <span v-for="(total,index) in itemsTotalQty" :class="itemsTotalQty.length > 1 && index == 0  ? 'pt-1' : ''">
                                            {{ formatThisNumber(total.Total.qty,uofmPrecision(total.Total.uofm)) + ' '+ total.Total.uofm }}
                                        </span>
                                    </div>
                                </v-col>
                                <v-col cols="1" lg="1" md="1" xs="3" class="pl-1 pr-3 pb-0">
                                    <div class="font-weight-black">{{ $t('message.cur') }}</div>
                                    <div class="mt-1 font-weight-black">{{ getCurrency(Wpr__currency_id) }}</div>
                                </v-col>
                                <v-col cols="1" lg="2" md="2" xs="3" class="pl-1 pr-3 pb-0">
                                    <div class="text-right font-weight-black">{{ $t('message.totalCost') }}</div>
                                    <div class="text-right font-weight-black">{{ formatThisNumber(itemsTotalAmount,currencyFormatPrecision) }}</div>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </template>
            </v-data-table>
        </v-form>
    </div>
</template>

<script>
import {generateDimension, generateSpec, numberFormat} from "Helpers/helpers";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {mapFieldsPrefixed, mapMultiRowFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {mapFields} from "vuex-map-fields";

export default {
    name: "WprItemListV2",
    props: ['itemListKey','updateMode','validate','items-valid','items-invalid'],
    data () {
        return {
            currentRowIndex: 0,
            dialogs: {
                confirm:  false,
                confirm_message: ""
            },
            filteredThickness: [],
            filteredUnits: [],
            resettingWprItems: false,
            validForm: false,
            // rules: {
            //     certification: v => typeof v === "number" || this.$t('message.required'),
            //     container: v => !!v || this.$t('message.required'),
            //     grade: v => !!v || this.$t('message.required'),
            //     itemQty: v => !!v || this.$t('message.required'),
            //     product: v => !!v || this.$t('message.required'),
            //     size: v => !!v || this.$t('message.required'),
            //     spec: v => !!v || this.$t('message.required'),
            //     species: v => !!v || this.$t('message.required'),
            //     unit: v => !!v || this.$t('message.required'),
            //     unitPrice: v => !!v || this.$t('message.required'),
            // }
        }
    },
    computed: {
        statePrefix() {
            if (this.updateMode) {
                return 'update'
            } else {
                return 'current'
            }
        },
        ...mapFieldsPrefixed('wpr', {
            Wpr__id: 'Wpr.id',
            Wpr__cfrvalue: 'Wpr.cfrvalue',
            Wpr__containercount: 'Wpr.containercount',
            Wpr__containersize_id: 'Wpr.containersize_id',
            Wpr__currency_id: 'Wpr.currency_id',
            Wpr__ms_id: 'Wpr.ms_id',
            Wpr__status_id: 'Wpr.status_id',
        },'statePrefix'),
        ...mapFields('wpr',{
            newWprSaved: 'newWprSaved',
            newWprUnsaved: 'newWprUnsaved',
            updatedWprSaved: 'updatedWprSaved',
            updatedWprUnsaved: 'updatedWprUnsaved'
        }),
        ...mapMultiRowFieldsPrefixed('wpritem',{
            Wpritems: 'Wpritems',
        },'statePrefix'),
        ...mapFieldsPrefixed('wpritem',{
            itemsTotalAmount: 'itemsTotalAmount',
            itemsTotalQty: 'itemsTotalQty'
        },'statePrefix'),
        ...mapGetters('certification',{
            allCertifications: 'allCertifications'
        }),
        ...mapGetters('currency',{
            allCurrencies: 'allCurrencies'
        }),
        ...mapGetters('grade',{
            allGrades: 'allGrades',
            findGradeById: 'findGradeById'
        }),
        ...mapGetters('mc', {
            allMoistureContent: 'allMoistureContent'
        }),
        ...mapGetters('productgroup',{
            allProductGroups: 'allProductGroups'
        }),
        ...mapGetters('size',{
            allSizes: 'allSizes',
            findSizeById: 'findSizeById'
        }),
        ...mapGetters('spec',{
            allSpecs: 'allSpecs',
            allCleanedSpecs: 'allCleanedSpecs',
            findSpecById: 'findSpecById'
        }),
        ...mapGetters('species', {
            allSpecies: 'allSpecies',
            findSpeciesById: 'findSpeciesById'
        }),
        ...mapGetters('thickness', {
            allThickness: 'allThickness'
        }),
        ...mapGetters([
            'containerSizes',
            'currencies',
            'uofmQty',
            'validationRules'
        ]),
        currencyFormatPrecision () {
            return this.allCurrencies.find((currency) => currency.Currency.id === this.Wpr__currency_id)?.Currency?.formatPrecision
        },
        headers() {
            let headers = [
                {
                    id: 0,
                    text: this.$t('message.product'),
                    value: 'Wpritem.productgroup_id'
                },
                {
                    id: 1,
                    text: this.$t('message.size'),
                    value: 'Wpritem.size_id'
                },
                {
                    id: 2,
                    text: this.$t('message.quantityPricing'),
                    value: 'Wpritem.volume'
                },
                {
                    id: 3,
                    text: this.$t('message.actions'),
                    value: 'index'
                }
            ]
            return headers
        },
        someWprData(){
            let data = {
                prefix: this.statePrefix,
                wpr: {
                    id: this.Wpr__id,
                }
            }
            return data
        }
    },
    methods: {
        ...mapActions('certification', {
            getAllCertifications: 'getAllCertifications'
        }),
        ...mapActions('currency', {
            getAllCurrencies: 'getAllCurrencies'
        }),
        ...mapActions('grade', {
            getAllGrades: 'getAllGrades'
        }),
        ...mapActions('mc', {
            getAllMoistureContent: 'getAllMoistureContent'
        }),
        ...mapActions('productgroup', {
            getAllProductGroups: 'getAllProductGroups'
        }),
        ...mapActions('size', {
            getAllSizes: 'getAllSizes'
        }),
        ...mapActions('spec', {
            getAllSpecs: 'getAllSpecs',
            getAllCleanedSpecs: 'getAllCleanedSpecs'
        }),
        ...mapActions('species', {
            getAllSpecies: 'getAllSpecies'
        }),
        ...mapActions('thickness', {
            getAllThickness: 'getAllThickness'
        }),
        ...mapActions('wpritem',{
            addWprItemRow: 'addWprItemRow',
            deleteWprItem: 'deleteWprItem',
            resetWprItems: 'resetWprItems',
            updateTotals: 'updateTotals'
        }),
        ...mapMutations('wpritem',{
            deleteWprItemRow: 'deleteWprItemRow',
        }),
        async deleteItem(itemIndex, itemId) {
            let _this = this
            if (await this.$root.$confirm(this.$t('message.delete'), this.$t('message.confirmations.continueDeleteItemAction'), {color: 'red'})) {
                let promise = new Promise((resolve, reject) => {
                    let payload = {
                        index: itemIndex,
                        prefix: _this.statePrefix
                    }
                    this.deleteWprItemRow(payload)
                    resolve('ok')
                });
                promise.then(()=>{
                    if(itemId != null){
                        this.deleteWprItem({
                            wpr_id: this.Wpr__id,
                            item_id: itemId}
                        )
                            .then((response)=>{
                                _this.updateTotals(_this.statePrefix)
                                    .then(()=>{
                                        _this.Wpr__cfrvalue = _this.itemsTotalAmount
                                    })
                            })
                    } else {
                        _this.updateTotals(_this.statePrefix)
                            .then(()=>{
                                _this.Wpr__cfrvalue = _this.itemsTotalAmount
                            })
                    }
                })
                .finally(()=>{
                    _this.updateTotals(_this.statePrefix)
                        .then(()=>{
                            _this.Wpr__cfrvalue = _this.itemsTotalAmount
                        })
                })
            }
        },
        filterGradesByProduct(productId){
            // return new Promise((resolve, reject) => {
                let filteredGrades = this.allGrades.filter(grade => {
                    return grade.Grade.productgroups.includes(productId)
                })
                if(filteredGrades.length > 0) {
                    return filteredGrades
                } else {
                    return []
                }
            // })
        },
        filterSpeciesByProduct(productId){
            // return new Promise((resolve, reject) => {
                let filteredSpecies = this.allSpecies.filter(species => {
                    return species.Species.productgroups.includes(productId)
                })
                if (filteredSpecies.length > 0) {
                    return filteredSpecies
                } else {
                    return []
                }
            // })
        },
        filterSpecsByProduct(productId){
            // return new Promise((resolve, reject) => {
                let filteredSpecs = this.allCleanedSpecs.filter(spec => {
                    return spec.Spec.productgroups.includes(productId)
                })
                if(filteredSpecs.length > 0) {
                    return filteredSpecs
                } else {
                    return []
                }
            // })
        },
        filterThickness(ms) {
            let various = this.allThickness.find(t => {
                return t.Thickness.ms == ms && t.Thickness.title == 'various'
            })
            let thicknesses = this.allThickness.filter(t => {
                return t.Thickness.ms == ms && t.Thickness.title != 'various'
            })
            if(various){
                this.filteredThickness = [various,...thicknesses]
            } else {
                this.filteredThickness = thicknesses
            }
        },
        formatThisNumber(value,format){
            return numberFormat(value,format)
        },
        getCurrency(currencyId) {
            let currency = this.allCurrencies.find(c => c.Currency.id == currencyId)
            if(currency)  return currency.Currency.code
            return ''
        },
        getCurrencyUnit(currencyId, uomId) {
            let currency = this.allCurrencies.find(c => c.Currency.id == currencyId)
            let uofm = this.uofmQty.find(m => m.Measurement.id == uomId)
            let currencyUnit = []
            if(currency) currencyUnit.push(currency.Currency.code)
            if(uofm) currencyUnit.push(uofm.Measurement.title)
            currencyUnit = currencyUnit.join('/')
            return currencyUnit
        },
        getSize(sizeId) {
            let size = this.allSizes.find(s => s.Size.id == sizeId)
            if(size != null){
                return size.Size.title
            } else {
                return null
            }
        },
        getUiDescription(item) {
            let uiDescription = []

            let species = this.allSpecies.find(s => s.Species.id == item.Wpritem.species_id)
            if(species) uiDescription.push(species.Species.title)

            let product = this.allProductGroups.find(p => p.ProductGroup.id == item.Wpritem.productgroup_id)

            if(item.Wpritem.description != null) {
                if (product && item.Wpritem.description.includes(product.ProductGroup.title) == false) uiDescription.push(product.ProductGroup.title)
                if (item.Wpritem.description != '') uiDescription.push(item.Wpritem.description)
            }

            uiDescription = uiDescription.join(', ')
            return uiDescription
        },
        getUnit(uomId) {
            let uofm = this.uofmQty.find(m => m.Measurement.id == uomId)
            if(uofm)  return uofm.Measurement.title
            return ''
        },
        getVolume() {
            let volume = []
            if(this.Wpr__containercount > 0) volume.push(this.Wpr__containercount)
            let container = this.containerSizes.find(c => c.Containersize.id == this.Wpr__containersize_id)
            if(container) volume.push(container.Containersize.title)
            return volume.join(' X ').toString()
        },
        async resetItems() {
            if (await this.$root.$confirm(this.$t('message.resetWprItems'), this.$t('message.confirmations.continueWprItemsReset'), {color: 'orange'})) {
                this.resettingWprItems = true
                this.resetWprItems(this.statePrefix).then(() => {
                    this.resettingWprItems = false
                })
            }
        },
        setItemDescription(idx) {
            if(idx != null) {
                let product = null
                let spec = null
                let mc = null

                let productGroupId = this.Wpritems[idx].Wpritem.productgroup_id
                let specId = this.Wpritems[idx].Wpritem.spec_id
                let mcId = this.Wpritems[idx].Wpritem.mc_id

                let productObj = this.allProductGroups.find(pg => pg.Productgroup?.id == productGroupId)
                let specObj = this.allCleanedSpecs.find(s => s.Spec.id == specId)
                if(specId == 'NEW'){
                    specObj = { Spec: this.Wpritems[idx]['Spec'] }
                }
                if(typeof specObj === 'undefined'){
                    specObj = { Spec: this.Wpritems[idx]['Spec'] }
                }
                let mcObj = this.allMoistureContent.find(mc => mc.Mc.id == mcId)

                if(productObj) product = productObj?.Productgroup?.title
                if(specObj) spec = specObj?.Spec?.title
                if(mcObj) mc = mcObj?.Mc?.title

                let description = generateSpec(product, spec, mc)
                this.Wpritems[idx].Wpritem.description = description
            }
        },
        uofmPrecision (uofm) {
            if(uofm) {
                if(isNaN(uofm)) {
                    return this.uofmQty.find((u) => u.Measurement.title == uofm.trim())?.Measurement?.formatPrecision
                } else {
                    return this.uofmQty.find((u) => u.Measurement.id == uofm)?.Measurement?.formatPrecision
                }
            } else {
                return '0,0.00'
            }
        },
        updateWprItemRowHandle(idx,field,value) {
            switch(field) {
                case 'Wpritem.grade_id':
                    var payload = {
                        index: idx,
                        field: field,
                        prefix: this.statePrefix,
                        value: value?.Grade?.id
                    }
                    // assign Grade property in Wpritem
                    this.$store.commit('wpritem/updateWprItems', {
                        index: idx,
                        field: 'Grade.id',
                        prefix: this.statePrefix,
                        value: value?.Grade?.id
                    })
                    this.$store.commit('wpritem/updateWprItems', {
                        index: idx,
                        field: 'Grade.title',
                        prefix: this.statePrefix,
                        value: value?.Grade?.title
                    })
                    break
                case 'Wpritem.productgroup_id':
                    if(typeof value === 'undefined') value = null
                    var payload = {
                        index: idx,
                        field: field,
                        prefix: this.statePrefix,
                        value: value,
                    }
                    //filter species
                    var species = this.filterSpeciesByProduct(value)
                    var speciesPayload = {
                        index: idx,
                        field: 'Options.species',
                        prefix: this.statePrefix,
                        value: species,
                    }
                    this.$store.commit('wpritem/updateWprItems', speciesPayload)

                    //filter grades
                    var grades = this.filterGradesByProduct(value)
                    var gradesPayload = {
                        index: idx,
                        field: 'Options.grades',
                        prefix: this.statePrefix,
                        value: grades,
                    }
                    this.$store.commit('wpritem/updateWprItems', gradesPayload)

                    //filter specs
                    var specs = this.filterSpecsByProduct(value)
                    var specsPayload = {
                        index: idx,
                        field: 'Options.specs',
                        prefix: this.statePrefix,
                        value: specs,
                    }
                    this.$store.commit('wpritem/updateWprItems', specsPayload)
                    break
                case 'Wpritem.size_id':
                    if(value) {
                        if(value?.Size?.id){ //value is an object
                            var payload = {
                                index: idx,
                                field: field,
                                prefix: this.statePrefix,
                                value: value?.Size?.id
                            }
                            this.$store.commit('wpritem/updateWprItems', {
                                index: idx,
                                field: 'Size.id',
                                prefix: this.statePrefix,
                                value: value?.Size?.id
                            })
                            this.$store.commit('wpritem/updateWprItems', {
                                index: idx,
                                field: 'Size.title',
                                prefix: this.statePrefix,
                                value: value?.Size?.title
                            })
                            this.$store.commit('wpritem/updateWprItems', {
                                index: idx,
                                field: 'Wpritem.size_text',
                                prefix: this.statePrefix,
                                value: value?.Size?.title
                            })
                        } else { //value is a string - new size
                            var payload = {
                                index: idx,
                                field: field,
                                prefix: this.statePrefix,
                                value: 'NEW'
                            }
                            this.$store.commit('wpritem/updateWprItems', {
                                index: idx,
                                field: 'Size.id',
                                prefix: this.statePrefix,
                                value: 'NEW'
                            })
                            this.$store.commit('wpritem/updateWprItems', {
                                index: idx,
                                field: 'Size.title',
                                prefix: this.statePrefix,
                                value: value
                            })
                            this.$store.commit('wpritem/updateWprItems', {
                                index: idx,
                                field: 'Wpritem.size_text',
                                prefix: this.statePrefix,
                                value: value
                            })
                        }
                    } else {
                        //if empty
                        var payload = {
                            index: idx,
                            field: field,
                            prefix: this.statePrefix,
                            value: null
                        }
                        this.$store.commit('wpritem/updateWprItems', {
                            index: idx,
                            field: 'Size.id',
                            prefix: this.statePrefix,
                            value: null
                        })
                        this.$store.commit('wpritem/updateWprItems', {
                            index: idx,
                            field: 'Size.title',
                            prefix: this.statePrefix,
                            value: null
                        })
                        this.$store.commit('wpritem/updateWprItems', {
                            index: idx,
                            field: 'Wpritem.size_text',
                            prefix: this.statePrefix,
                            value: null
                        })
                    }
                    break
                case 'Wpritem.spec_id':
                    if(value) {
                        if(value?.Spec?.id) { //value is an object
                            var payload = {
                                index: idx,
                                field: field,
                                prefix: this.statePrefix,
                                value: value?.Spec?.id
                            }
                            // assign Spec property in Wpritem
                            this.$store.commit('wpritem/updateWprItems', {
                                index: idx,
                                field: 'Spec.id',
                                prefix: this.statePrefix,
                                value: value?.Spec?.id
                            })
                            this.$store.commit('wpritem/updateWprItems', {
                                index: idx,
                                field: 'Spec.title',
                                prefix: this.statePrefix,
                                value: value?.Spec?.title
                            })

                            //update Wpritem.mc_id if empty and Spec.mc_id is not - VJC @ 2021-08-06
                            let specObj = this.allSpecs.find(s => s.Spec.id == value?.Spec?.id)
                            if(specObj && specObj?.Spec.mc_id != null) {
                                this.Wpritems[idx]['Wpritem']['mc_id'] = specObj?.Spec?.mc_id
                            }

                        } else { //value is a string - new spec
                            var payload = {
                                index: idx,
                                field: field,
                                prefix: this.statePrefix,
                                value: 'NEW'
                            }
                            // assign Spec property in Wpritem
                            this.$store.commit('wpritem/updateWprItems', {
                                index: idx,
                                field: 'Spec.id',
                                prefix: this.statePrefix,
                                value: 'NEW'
                            })
                            this.$store.commit('wpritem/updateWprItems', {
                                index: idx,
                                field: 'Spec.title',
                                prefix: this.statePrefix,
                                value: value
                            })
                        }
                    } else {
                        //if empty
                        var payload = {
                            index: idx,
                            field: field,
                            prefix: this.statePrefix,
                            value: null
                        }
                        // assign Spec property in Wpritem
                        this.$store.commit('wpritem/updateWprItems', {
                            index: idx,
                            field: 'Spec.id',
                            prefix: this.statePrefix,
                            value: null
                        })
                        this.$store.commit('wpritem/updateWprItems', {
                            index: idx,
                            field: 'Spec.title',
                            prefix: this.statePrefix,
                            value: null
                        })
                    }
                    break
                case 'Wpritem.species_id':
                    var payload = {
                        index: idx,
                        field: field,
                        prefix: this.statePrefix,
                        value: value,
                    }
                    //assign Species property in Wpritem
                    var species = this.findSpeciesById(value);
                    this.$store.commit('wpritem/updateWprItems', {
                        index: idx,
                        field: 'Species.id',
                        prefix: this.statePrefix,
                        value: species?.Species?.id ? species.Species.id : null
                    })
                    this.$store.commit('wpritem/updateWprItems', {
                        index: idx,
                        field: 'Species.title',
                        prefix: this.statePrefix,
                        value: species?.Species?.title ? species.Species.title : null
                    })
                    break
                case 'Wpritem.measurement_id':
                    var payload = {
                        index: idx,
                        field: field,
                        prefix: this.statePrefix,
                        value: value,
                    }
                    //assign Measurement property in
                    let measurement = this.uofmQty.filter(uofm => uofm.Measurement.id === value)[0];
                    if(measurement != null) {
                        this.$store.commit('wpritem/updateWprItems', {
                            index: idx,
                            field: 'Measurement.id',
                            prefix: this.statePrefix,
                            value: measurement.Measurement.id != null ? measurement.Measurement.id : null
                        })
                        this.$store.commit('wpritem/updateWprItems', {
                            index: idx,
                            field: 'Measurement.title',
                            prefix: this.statePrefix,
                            value: measurement.Measurement.title != null ? measurement.Measurement.title : null
                        })
                    }
                    break
                case 'Wpritem.thickness_id':
                case 'Wpritem.width':
                case 'Wpritem.length':
                    //update size_text
                    let thicknessId = this.Wpritems[idx].Wpritem.thickness_id
                    let width = this.Wpritems[idx].Wpritem.width
                    let length = this.Wpritems[idx].Wpritem.length
                    if(field == 'Wpritem.thickness_id'){
                        thicknessId = value
                    }
                    if(field == 'Wpritem.width'){
                        width = value
                    }
                    if(field == 'Wpritem.length'){
                        length = value
                    }

                    const thicknessObj = this.allThickness.find(t => t.Thickness.id == thicknessId)
                    const thickness = thicknessObj?.Thickness.title
                    let unit = thicknessObj?.Thickness.suffix
                    if(unit == null){
                        unit = ''
                    }

                    const sizeText = generateDimension(thickness,width,length, unit)

                    this.$store.commit('wpritem/updateWprItems', {
                        index: idx,
                        field: 'Wpritem.size_text',
                        prefix: this.statePrefix,
                        value: sizeText
                    })

                    //update Size object
                    //check if size exists
                    let sizeId = null
                    let sizeObj = this.allSizes.find(s => s.Size.title == sizeText)
                    if(sizeObj){
                        sizeId = sizeObj?.Size.id
                    } else {
                        if (sizeText.length > 0) {
                            sizeId = 'NEW' //new size object
                        }
                    }

                    this.$store.commit('wpritem/updateWprItems', {
                        index: idx,
                        field: 'Size.id',
                        prefix: this.statePrefix,
                        value: sizeId
                    })
                    this.$store.commit('wpritem/updateWprItems', {
                        index: idx,
                        field: 'Size.title',
                        prefix: this.statePrefix,
                        value: sizeText
                    })
                    this.$store.commit('wpritem/updateWprItems', {
                        index: idx,
                        field: 'Wpritem.size_id',
                        prefix: this.statePrefix,
                        value: sizeId
                    })

                    var payload = {
                        index: idx,
                        field: field,
                        prefix: this.statePrefix,
                        value: value,
                    }
                    break
                default:
                    if(typeof value === 'undefined') value = null
                    var payload = {
                        index: idx,
                        field: field,
                        prefix: this.statePrefix,
                        value: value,
                    }
            }
            let promise = new Promise((resolve, reject) => {
                this.$store.commit('wpritem/updateWprItems', payload)
                resolve('ok')
            });
            let _this = this
            promise.then(()=>{
                if(['Wpritem.productgroup_id','Wpritem.spec_id','Wpritem.grade_id','Wpritem.mc_id'].includes(field)){
                    this.setItemDescription(idx)
                }
                if(['Wpritem.volume','Wpritem.unitprice'].includes(field)) {
                    _this.updateTotals(_this.statePrefix)
                        .then(() => {
                            _this.Wpr__cfrvalue = _this.itemsTotalAmount
                        })
                }
            })
        }
    },
    watch: {
        itemsLoaded(value) {
            if (value && this.updateMode) {
                this.$nextTick(function () {
                    this.Wpritems.forEach((item, idx) => {
                        //filter species
                        let species = this.filterSpeciesByProduct(item.Wpritem.productgroup_id)
                        let speciesPayload = {
                            index: idx,
                            field: 'Options.species',
                            prefix: this.statePrefix,
                            value: species,
                        }
                        this.$store.commit('wpritem/updateWprItems', speciesPayload)

                        //filter grades
                        let grades = this.filterGradesByProduct(item.Wpritem.productgroup_id)
                        let gradesPayload = {
                            index: idx,
                            field: 'Options.grades',
                            prefix: this.statePrefix,
                            value: grades,
                        }
                        this.$store.commit('wpritem/updateWprItems', gradesPayload)

                        //filter specs
                        let specs = this.filterSpecsByProduct(item.Wpritem.productgroup_id)
                        let specsPayload = {
                            index: idx,
                            field: 'Options.specs',
                            prefix: this.statePrefix,
                            value: specs,
                        }
                        this.$store.commit('wpritem/updateWprItems', specsPayload)
                    })
                })
            }
        },
        Wpr__ms_id(value){
            this.filteredUnits = this.uofmQty.filter(u => {
                return u.Measurement.system == value || u.Measurement.system == null
            })
            this.filterThickness(value)
        },
        validate(value) {
            if(value){
                let valid = this.$refs.wprItemsForm.validate()
                if(valid){
                    this.$emit('items-valid')
                } else {
                    this.$emit('items-invalid')
                }
            }
        }
    },
    created() {
        if(this.allMoistureContent.length == 0) this.getAllMoistureContent()
        if(this.allProductGroups.length == 0) this.getAllProductGroups()
        if(this.allSpecies.length == 0) this.getAllSpecies()
        if(this.allGrades.length == 0) this.getAllGrades()
        if(this.allSizes.length == 0) this.getAllSizes()
        if(this.allSpecs.length == 0) this.getAllSpecs()
        if(this.allCleanedSpecs.length == 0) this.getAllCleanedSpecs()
        if(this.allThickness.length == 0) this.getAllThickness()
        if(this.allCertifications.length == 0) this.getAllCertifications()
        if(this.allCurrencies.length == 0) this.getAllCurrencies()
        // this.$store.commit('wpritem/resetState','current')
        // this.resetWprItems(this.statePrefix)

        let _this = this
        setTimeout(function(){
            if(_this.Wpr__ms_id == null) _this.Wpr__ms_id = 1
            _this.filteredUnits = _this.uofmQty.filter(u => {
                return u.Measurement.system == _this.Wpr__ms_id || u.Measurement.system == null
            })
            // _this.filteredThickness = _this.allThickness.filter(t => {
            //     return t.Thickness.ms == _this.Wpr__ms_id
            // })
            _this.filterThickness(_this.Wpr__ms_id)
        },500)
    },
    mounted() {
        // this.filteredUnits = this.uofmQty.filter(u => {
        //     return u.Measurement.system == this.Wpr__ms_id || u.Measurement.system == null
        // })
        // this.filteredThickness = this.allThickness.filter(t => {
        //     return t.Thickness.ms == this.Wpr__ms_id
        // })
        this.$refs.wprItemsForm.resetValidation()
        this.$store.watch(
            function (state) {
                return state.appic.wpritem.update.Wpritems
            },
            () => {
                this.updatedWprUnsaved = true
            },
            {deep: true}
        )
        this.$store.watch(
            function (state) {
                return state.appic.wpritem.current.Wpritems
            },
            () => {
                this.newWprUnsaved = true
            },
            {deep: true}
        )
        this.$nextTick(function () {
            if (this.updateMode) {
                this.Wpritems.forEach((item, idx) => {
                    //filter species
                    let species = this.filterSpeciesByProduct(item.Wpritem.productgroup_id)
                    let speciesPayload = {
                        index: idx,
                        field: 'Options.species',
                        prefix: this.statePrefix,
                        value: species,
                    }
                    this.$store.commit('wpritem/updateWprItems', speciesPayload)

                    //filter grades
                    let grades = this.filterGradesByProduct(item.Wpritem.productgroup_id)
                    let gradesPayload = {
                        index: idx,
                        field: 'Options.grades',
                        prefix: this.statePrefix,
                        value: grades,
                    }
                    this.$store.commit('wpritem/updateWprItems', gradesPayload)

                    //filter specs
                    let specs = this.filterSpecsByProduct(item.Wpritem.productgroup_id)
                    let specsPayload = {
                        index: idx,
                        field: 'Options.specs',
                        prefix: this.statePrefix,
                        value: specs,
                    }
                    this.$store.commit('wpritem/updateWprItems', specsPayload)
                })
            }
        })
    },
    updated() {
        this.$nextTick(function () {
            if(this.updateMode) {
                this.Wpritems.forEach((item, idx) => {
                    //filter species
                    let species = this.filterSpeciesByProduct(item.Wpritem.productgroup_id)
                    let speciesPayload = {
                        index: idx,
                        field: 'Options.species',
                        prefix: this.statePrefix,
                        value: species,
                    }
                    this.$store.commit('wpritem/updateWprItems', speciesPayload)

                    //filter grades
                    let grades = this.filterGradesByProduct(item.Wpritem.productgroup_id)
                    let gradesPayload = {
                        index: idx,
                        field: 'Options.grades',
                        prefix: this.statePrefix,
                        value: grades,
                    }
                    this.$store.commit('wpritem/updateWprItems', gradesPayload)

                    //filter specs
                    let specs = this.filterSpecsByProduct(item.Wpritem.productgroup_id)
                    let specsPayload = {
                        index: idx,
                        field: 'Options.specs',
                        prefix: this.statePrefix,
                        value: specs,
                    }
                    this.$store.commit('wpritem/updateWprItems', specsPayload)
                })
            }
        })
    }
}
</script>

<style>
.width-45-pct {
    width: 45%;
}
.bg-lightgrey {
    background-color: #F5F5F5;
}
.col-lg-4-5 {
    flex: 0 0 37.5;
    max-width: 37.5% !important;
}
.col-lg-7-5 {
    flex: 0 0 62.5;
    max-width: 62.5% !important;
}
.v-text-field__slot input {
    text-align: right;
}
.custom.v-text-field>.v-input__control>.v-input__slot:before {
    border-style: none;
}
.custom.v-text-field>.v-input__control>.v-input__slot:after {
    border-style: none;
}
.v-data-table td {
    padding: 0 5px;
}
.font-16px {
    font-size: 16px !important;
}
.font-xs {
    font-size: 0.65rem;
}
.mt-5px {
    margin-top: 5px !important;
}
.mt-6px {
    margin-top: 6px !important;
}
.custom.v-select .v-icon {
    font-size: 18px !important;
}
.truncate {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table tbody td {
    vertical-align: top !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    height: 40px !important;
}
.dense-inputs .v-input {
    font-size: 16px !important;
}
</style>